<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <div class="form-row">
          <sgv-input-textarea
            label="ชื่อในการซื้อขาย"
            :disabled="$auth.disabled(method)"
            class="col-12"
            autoGrow
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-textarea>

          <DocConfigFormInventory
            :disabled="$auth.disabled(method)"
            :docType="docType"
            :templateType="templateType"
            ref="inventory"
            class="col-12"
            select="id"
            :types="types"
            v-model="formData.inventoryId"
            :validations="[
              {text: 'required!', value: $v.formData.inventoryId.$dirty && !$v.formData.inventoryId.required}
            ]">
          </DocConfigFormInventory>

          <DetailInventoryAccountInput
            label="บัญชี"
            placeholder="ค่าเริ่มต้น"
            :disabled="$auth.disabled(method)"
            :docType="docType"
            :templateType="templateType"
            :type="type"
            class="col-12"
            select="id"
            v-model="formData.parentId">
          </DetailInventoryAccountInput>
        </div>
      </sgv-form>
    </div>

    <HrDivider :options="tabs" v-model="selected" class="mt-3"></HrDivider>

    <div v-for="tab in tabs" :key="tab.value">
      <component
        v-if="selected === tab.value"
        :is="tab.value"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        :parentId="docConfigId">
      </component>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory'
import DetailInventoryAccountInput from './DetailInventoryAccountInput'
import DetailInventoryConverter from './DetailInventoryConverter'
import {
  LIST_INVENTORY_TYPE,
  DETAIL_INVENTORY,
  CREATE_INVENTORY,
  UPDATE_INVENTORY,
  DESTROY_INVENTORY,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailInventory`,
      formData: {
        name: '',
        inventoryId: null,
        parentId: null
      },
      isLoaded: false,
      types: [],
      rFields: ['selected'],
      selected: 'DetailInventoryConverter'
    }
  },
  validations: {
    formData: {
      name: { required },
      inventoryId: { required }
    }
  },
  apollo: {
    types: {
      query () {
        return LIST_INVENTORY_TYPE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          type: this.type,
        }
      },
      result (res) {
        if (this.method === 'add' && res.data.types.length > 0) {
          this.$nextTick(() => {
            this.$refs.inventory.setGroup(res.data.types[0])
          })
        }
      }
    }
  },
  computed: {
    tabs () {
      if (this.docConfigId <= 0) return []

      return [
        {text: 'แปลงหน่วย', value: 'DetailInventoryConverter'},
      ]
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          docConfigId: this.docConfigId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.inventory)})
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = []

      removeKeys.forEach(x => delete input[x])

      return input
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docConfigId: res.data.inventory.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          docConfigId: this.docConfigId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          docConfigId: this.docConfigId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    selected: 'setRetaining'
  },
  created () {
    if (this.docConfigId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DocConfigFormInventory,
    DetailInventoryAccountInput,
    DetailInventoryConverter
  }
}
</script>

<style lang="css">
</style>
